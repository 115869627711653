/*
 * Keyframes
 */

 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
  }
  
/*
 * CSS Page Transitions
 * Don't forget to add vendor prefixes!
 */
 .m-scene {
    /** Basic styles for an animated element */
    .scene_element {
      animation-duration: 3s;
      transition-timing-function: ease-in-out;
      animation-fill-mode: both;
      &--1s{
        animation-duration: 1s;
      }
      &--2s{
        animation-duration: 2s;
      }
      &--4s{
        animation-duration: 4s;
      }
    }
  
    /** An element that fades in */
    .scene_element--fadein {
      animation-name: fadeIn;
    }
  
    /** An element that fades in and slides up */
    .scene_element--fadeinup {
      animation-name: fadeInUp;
    }
  
    /** An element that fades in and slides from the right */
    .scene_element--fadeinright {
      animation-name: fadeInRight;
    }
  }