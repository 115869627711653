// donate page

#donate {
    .main{
        .container{
            max-width: 60vw;
            margin-top: 20px;
            margin-bottom: 20px;
            iframe{
                border-radius: 25px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #donate {
        .main{
            .container{
                min-height: unset;
                max-width: 95vw;
            }
        }
    }
}