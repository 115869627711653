@font-face {
  font-family: 'Rubik';
  src: url("../fonts/Rubik/Rubik-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'Rubik Italic';
  src: url("../fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: 'Heebo';
  src: url("../fonts/Heebo/Heebo-VariableFont_wght.ttf");
}

body {
  background-color: $bodyGray;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Heebo', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  padding-top: 55px;
}

.middle {
  display: block;
  margin: 0 auto;
}

.hide {
  display: none;
}

.footerIcon,
.icon {
  width: 30px;
  height: 30px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red {
  color: $red;
}

.text-blue {
  color: $blue1;
}

.text-turquoise {
  color: $turquoise;
}

img {
  max-width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.p0 {
  padding: 0;
}

.page-title-box {
  text-align: center;
  background-color: white;
  color: $gray;
  border-radius: 10px;
  margin: 30px auto;
  padding: 15px;
  max-width: 800px;
  width: 100%;
}

.sub-title {
  color: white;
  background-color: $gray;
  padding: 5px 12px;
  border-radius: 5px;
  width: fit-content;
  margin: 0 auto;

  &.invert {
    background-color: white;
    color: black;

  }

  &.text-blue {
    color: $blue1;
  }

  &.title-box {
    text-align: center;
    margin: 30px auto;

    a {
      color: white;
      text-decoration: none;
    }
  }

  a {
    color: white;
  }
}


.main-title {
  font-size: 65px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.accordion-button {
  &::after {
    margin-left: 15px;
  }
}

.main {
  .container {
    min-height: 88vh;
  }
}

.btn-block {
  width: 100%;
}

/* Desktop*/
@media only screen and (min-width: 1300px) {

  .logo-box {
    margin: 20px;

    i.bi {
      color: $blue1;
      font-size: 80px;
    }

    img {
      max-width: 100px;
      max-height: 100px;
    }

    .home-main-role-icon {
      margin: 20px;
    }

    h4 {
      top: 20px;
      position: relative;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .logo-box {
    margin: 16px;

    i.bi {
      color: $blue2;
      font-size: 70px;
    }

    img {
      max-width: 70px;
      max-height: 70px;
    }

    .home-main-role-icon {
      margin: 16px;
    }
    h4 {
      top: 20px;
      position: relative;
    }
  }

}

@media only screen and (max-width: 1600px) {
  .desktop-large {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .main-title {
    font-size: 35px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none;
  }

  .title-box {
    margin: 40px auto;
  }
}