//   navbar:
.navbar-dark,
.navbar[data-bs-theme=dark] {
    background-color: $blue3 !important;
    height: unset;
}

.navbar-nav.navbar-nav-scroll {
    background-color: $blue3 !important;
}

.nav-link:hover {
    text-decoration-line: underline;
    font-weight: bold;
    color: white;
}

.nav-link {
    color: $blue;
    padding: 5px;
    margin: 0 10px;
    text-align: center;
}

.system-theme-link {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid white;

    &.dark-theme {
        color: black;
        border: 1px solid black;
    }
}

@media only screen and (min-width: 768px) {

    .navbar-dark,
    .navbar[data-bs-theme=dark] {
        max-width: 60vw;
        height: 6vh;
    }

    #app-header {
        .navbar {
            border-radius: 0 0 15px 15px;
        }
    }

    #app-footer {
        .navbar {
            border-radius: 15px 15px 0 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .nav-link {
        border-top: 1px solid white;
    }
}

@media only screen and (max-width: 1600px) {

    .navbar-dark,
    .navbar[data-bs-theme=dark] {
        height: auto;
    }
}