@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import './variables.scss';
@import './common.scss';
@import './navbar.scss';
@import './imagesCarousel.scss';
@import './animations.scss';
@import './donate.scss';
@import './aboutUs.scss';
@import './team.scss';
@import './home.scss';
@import './lightTheme.scss';

// contact page:
#contactPage,
#loginPage {
  max-width: 500px;
  padding-bottom: 20px;
}

// media page
#media-page {
  max-width: 65vw;
  margin-top: 20px;

  .media-row {
    margin: 30px 0;

    .card {
      margin: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {

  // media page
  #media-page {
    max-width: unset;
    margin-top: 30px;

    .media-row {
      margin: unset;

      .card {
        margin: 20px 0;
      }
    }
  }
}

//events page

.event-section {
  max-width: 600px;
  margin: 0 auto;
}

.article-section {
  max-width: 800px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}

//admin
.admin-continer {
  max-width: 60vw;
}

@media only screen and (max-width: 768px) {
  .admin-continer {
    max-width: 100vw;
  }
}