//Images Carousel
.carousel-container {
    .carousel-inner {
        .carousel-item {
            margin-right: -100%;
            margin-left: unset;

            &.active {
                margin-right: 0;
                margin-left: unset;
            }

            img {
                max-width: 100%;
                height: 300px;
                background-size: cover;
            }

            .carousel-item.active {
                margin-left: 0;
            }

        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        filter: invert(0) grayscale(0) !important;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next {
        float: left;
        left: 0;
        right: unset;
    }

    .carousel-control-prev {
        float: right;
        right: 0;
        left: unset;
    }
}