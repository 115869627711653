.app-root {
    background-color: $bodyGray;
    &.light-theme {
        background-color: $lightGray;
        color: black;

        #home-page {

            #home-about-us-section {
                .card {
                    // background-color: rgba(240, 240, 240, 0.4);
                    color: black;
                }
            }

            #home-page-section-3 {
                i.bi {
                    color: black;
                }
            }
        }

        .page-title-box {
            background-color: $gray;
            color: white;
        }

        .title-box {
            color: $lightGray;
        }

        .main-title {
            color: $gray;
        }

        #about-us {
            #mainCard {
                background-color: rgba(255, 255, 255, 0.9);
            }
        }

        #teamPage {
            .card-body {
                color: black;
            }
        }
    }
}