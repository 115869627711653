// donate page

#about-us {
    .main {
        .container {
            max-width: 60vw;
            padding-top: 2vh;
            padding-bottom: 2vh;
        }
    }

    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    background-image: url('../images/Donate.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    #mainCard {
        max-width: 90%;
        margin: 0 auto;
        padding: 20px;
        background-color: rgba(120, 120, 120, 0.6);
    }

    p {
        font-size: 1.1em;
    }

    h3 {
        line-height: 1.6;
    }

    .accordion {
        height: 75vh;
        padding: 2vh 0;
    }

    .donate-button {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 768px) {
    #about-us {
        background-image: unset;
        .main {
            .container {
                min-height: unset;
                max-width: 95vw;
                padding: 0;
            }
        }

        #mainCard {
            max-width: 100%;
            width: 100%;
            padding: unset;
            margin: 1vh auto;
        }

        #main-title {
            font-size: 30px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 16px;
        }

        .accordion {
            height: auto;
            min-height: 70vh;
            padding-top: 0;
        }

        p {
            font-size: 1em;
        }
    }
}
@media only screen and (max-width: 1600px) {
    #about-us {
        .accordion {
            height: auto;
            min-height: 70vh;
            padding-top: 0;
            overflow: auto;
        }
    }
}
