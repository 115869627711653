.team-card {
    background-color: transparent;
    border: none;
    padding: 30px;
    cursor: pointer;

    img {
        width: 225px;
        height: 225px;
        margin: 0 auto;

        :hover {
            width: 240px;
            height: 240px;
        }

    }

    .card-body {
        text-align: center;
        color: white;

        .card-text {
            min-height: 120px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .team-card {
        padding: 10px;

        img {
            padding: 10px;
            width: 250px;
            height: 250px;
        }

        .card-body {
            .card-text {
                min-height: unset;
            }
        }
    }
}