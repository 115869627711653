#home-page {
    #home-title {
        width: 100%;
    }

    #home-page-section-3 {
        max-width: 600px;
        margin: 30px auto;

        i.bi {
            color: white;
        }

        h2 {
            color: $blue2;
        }

        .main-title {
            margin-bottom: 20px;
        }

        a {
            text-decoration: none;

            :hover {
                text-decoration: underline;
            }
        }

    }

    #home-about-us-section {
        margin: 10px auto;

        .card {
            border: none;
            background-color: $blue1;
            text-align: justify;
        }

        .card-text {
            font-size: 18px;
            color: white;
        }
    }

    hr {
        margin: 40px auto;
        max-width: 600px;
    }
}

/* Desktop*/
@media only screen and (min-width: 1300px) {
    // home-page

    #home-page {
        #home-title {
            img {
                width: 60vw;
                border-radius: 25px
            }
        }

        #home-about-us-section {
            max-width: 800px;
            margin: 20px auto;

            .card {
                padding: 20px 40px;
                line-height: 1.8;
                font-size: 1.1em;
            }
        }

    }
}