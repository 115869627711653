$darkRed: #740403;
$red: red;
$blue: blue;
$blue1: #65a6da;
$blue2: #0d6efd;
$blue3: #60A5E0;
$turquoise: #00A6ED;

$gray: #2a292a;
$gray2: #070707;
$bodyGray: #0000009d;
$lightGray: #e7e7e7;
$lightGray2: #fafafa;